//Variables
$main-color: #38799C;
$main-accent: #F98526;
$white: #FFFFFF;
$main-dark: #434D52;
$main-light: #7D8A90;
$header-background-color: transparent;
$header-text-color: #E2EDF3;
$fixed-header-background-color: $main-color;
$fixed-header-text-color: #FFFFFF;

* {
  box-sizing: border-box;
}

.underline-hover-defaults {
  transition: 0.1s all 0s linear;
  content: '';
  height: 2px;
  width: 0;
  position: absolute;
  bottom: -4px;
  background-color: $main-color;
}

.underline-hover-animation {
  position: relative;

  &::before {
    @extend .underline-hover-defaults;
    left: 50%;
  }

  &::after {
    @extend .underline-hover-defaults;
    left: 50%;
  }

  &:hover {
    &::before {
      width: 50%;
      left: 0;
    }
    &::after {
      width: 50%;
    }
  }
}

.cf {
  &::after {
    display: table;
    content: '';
    clear: both;
  }
}

@mixin transform($options) {
  -ms-transform: $options;
  -moz-transform: $options;
  -webkit-transform: $options;
  -o-transform: $options;
  transform: $options;
}

.center-horizontal {
  @include transform(translate(-50%,0));
}

.center-vertical {
  @include transform(translate(0,-50%));
}

.center-both {
  @include transform(translate(-50%,-50%));
}

.importing_fav_icon {
  background: url("../img/favicon.ico");
}

body {
  background: url("../img/furley_bg.png");
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  position: relative;
  max-width: 100%;
}

header {
  height: 82px;

  nav {
    padding: 15px 75px;
    margin: auto;
    z-index: 3;
    padding-top:  20px;
    width: 100%;
    line-height: 28px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.3s all 0s ease-in-out;
    box-shadow: none;
    background-color: transparent;
  }

  .site-name {
    font-size: 24px;
    display: inline-block;
  }

  span, a {
    color: $header-text-color;
    text-decoration: none;
    text-shadow: 0 0 3px #222;
    cursor: pointer;
  }


  .mobile-nav-toggle {
    display: none;
  }

  ul {
    list-style-type: none;
    float: right;

    li {
      display: inline-block;
      font-size: 14px;
      padding: 0 10px;

      span, a {
        @extend .underline-hover-animation;
      }
    }

    &.active {
      span, a {
        &::before {
          width: 50%;
          left: 0;
        }
        &::after {
          width: 50%;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .background {
    background: url('../img/homepage_film_desktop.jpg') no-repeat center 25%;
  }
}

.background {
  background-size: cover;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.4);
  }
}

//Main
main {
  width: 100%;
  color: $main-dark;
  z-index: 3;
  font-size: 0;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: $main-color;
    }
  }
}

.section-wrapper {
  width: 100%
}

section {
  max-width:  1000px;
  margin: auto;
  vertical-align: top;
  color: $main-dark;
  position: relative;
  padding: 50px 15px;

  &.headline {
    padding: 30px 0 50px;
    color: white;
    line-height: 1.1;
    text-shadow: 0 0 3px #222;

    h2 {
      color: lighten($main-color, 90%);
      font-size: 37px;
      padding-bottom: 12px;
      font-weight: 400;
    }
  }

  &.about {
    padding: 50px;
    color: $main-dark;
    font-size: 22px;
    line-height: 1.4;
    text-align: center;
  }
}

.btn {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  margin: 20px 0 20px;
  text-align: center;

  a, span {
    color: $white;
    text-decoration: none;
    display: inline-block;
    padding: 15px 40px;
    border-radius: 4px;
    background-color: $main-color;

    &:hover {
      color: $white;
    }
  }
}

.shows {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1100px;
  margin: auto;
  align-items: flex-start;
}

.shows-item {
  width: 31%;
  padding: 0;
  margin: 0 0 24px;
  border-radius: 3px;
  background-color:  #fff;
  box-shadow: 0 20px 25px -12px rgba(0,0,0,0.09);
}

.information {
  padding: 16px 16px 0;
}

h3 {
  font-size: 26px;
  color: $main-color;
  font-weight: 400;
  margin: 0 0 16px;
  line-height: 1.3;
}

.network {
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  color: $main-light;
  font-weight: 700;
  margin: 0 0 10px;
}

.small-details {
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.format, .editordetails {
  text-transform: uppercase;
  font-size: 14px;
  color: $main-light;
  font-weight: 400;
  margin: 0 0 5px;
  line-height: 1.4;
}

.format {
  margin-right: 10px;
}

.summary {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 16px;
}

.graphic {
  width: 100%;

  img {
    width: 100%;
    border-radius: 3px 3px 0 0;
  }
}

.show_links {
  @extend .cf;
  display: block;
  margin: 0 16px 16px;
  padding: 8px 0 0;
  border-top: 1px solid lighten($main-color, 30%);
}

.website, .video_link {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  color: $main-color;
  float: left;
  cursor: pointer;
}

.video_link {
  float: right;
}

//Contact Section
.contact {
  text-align: center;

  h2 {
    font-size: 32px;
    font-weight: 400;
    color: $main-color;
  }
}

.contact-form {
  width: 75%;
  display: inline-block;
  padding: 10px 30px 30px;
  text-align: left;

  label {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    width: 30%;
    display: inline-block;
    vertical-align: top;
    padding-top:  10px;
    position: relative;

    .error {
      position: absolute;
      left: 0;
      bottom: -20px;
      font-size: 12px;
      opacity: 0;
      transition: 0.2s opacity 0s linear;
      color: red;
      text-transform: none;

      &.shown {
        opacity: 1;
      }
    }
  }

  input, textarea {
    font-size: 18px;
    padding: 10px;
    outline: none;
    width: 70%;
    border: 1px solid rgba(0,0,0,0.2);
  }

  textarea {
    height: 200px;
  }

  .form-note {
    display: inline-block;
    font-size: 14px;
    width: 70%;
    margin-left: 30%;
    margin-bottom: 10px;
  }

  .form-element {
    width: 100%;
    margin: 20px 0;
  }

  .form-element-submit {
    text-align: center;
    width: 70%;
    display: inline-block;
    margin-left: 30%;
    cursor: pointer;
  }

  .contact_form_overlay {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	background-color: rgba(255,255,255,0.7);
  	font-size: 34px;
  	display: none;

  	&.show {
      display: block;
    }
  }
}

.contact-form-message {
	position: absolute;
	top: 50%;
	left: 50%;
	@extend .center-both;
	width: 100%;
	padding: 20px;
	text-align: center;
  line-height: 1.3;
}

.contact-form-success {
  color: green;
}

.contact-form-error {
  color: #d32F2f;
}


.video_container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);

  .vjs-vimeo {
    height: auto;
  }

  .video-js .vjs-big-play-button {
    display: none;
  }

  &.show {
    display: block;
  }

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    @extend .center-both;
    max-width:1080px;
    width: 100%;
  }

  .close_video {
    position: absolute;
    top: 50px;
    right: 50px;
    color: #FFF;
    cursor: pointer;
    font-size: 50px;
    font-weight: bold;
    opacity: 0.5;
    z-index: 100;
  }
}
