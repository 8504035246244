@media screen and (max-width: 600px) {
  .background {
    background: url('../img/homepage_film_mobile.jpg') no-repeat center 25%;
    height: 270px;
  }

  header {
    height: 50px;

    nav {
      padding: 10px;
    }

    .site-name {
      font-size: 18px;
    }

    ul {
      li {
        font-size: 13px;
        padding: 0 5px;
      }
    }
  }

  section {
    padding: 15px;
    max-width: 100%;

    &.headline {
      padding: 0;

      h2 {
        font-size: 24px;
        padding: 16px;
      }
    }

    &.about {
      padding: 50px 15px 15px;
    }
  }

  .shows {
    margin-top: 24px;
  }

  .shows-item {
    width: 100%;
    margin: 0px 15px 24px;
  }

  .contact-form {
    width: 100%;
    display: block;
    padding: 10px;

    label {
      font-size: 16px;
      width: 35%;
    }

    input, textarea {
      width: 65%;
    }

    .form-note {
      width: 65%;
      margin-left: 35%;
    }
  }

  .video_container {
    max-width: 100%;
  }

  .video {
    max-width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .background {
    height: 320px;
  }
}